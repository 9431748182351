var registrationColumn = {
  id: 'id',
  name: 'name',
  middleName: 'middleName',
  lastName: 'lastName',
  phone: 'phone',
  email: 'email',
  confirmEmail: 'confirmEmail',
  type: 'type',
  paymentIntentId: 'paymentIntentId',
  typeName: 'typeName',
  chargeAmount: 'chargeAmount',
  parentId: 'parentId',
  contextId: 'contextId',
  guests: 'guests',
  guestCount: 'guestCount',
  terms: 'terms',
  chargeCurrency: 'chargeCurrency',
  createdOn: 'createdOn',
  updatedOn: 'updatedOn',
  nameGroup: 'nameGroup',
  companyGroup: 'companyGroup',
  emailGroup: 'emailGroup',
  accessKey: 'accessKey',
  status: 'status',
  company: 'company',
  jobTitle: 'jobTitle',
  errors: 'errors',
  loginCount: 'loginCount',
  profileImageUrl: 'profileImageUrl',
  description: 'description',
  isPublicProfile: 'isPublicProfile',
  admittedOn: 'admittedOn',
  groups: 'groups',
  sessions: 'sessions',
  previousSessions: 'previousSessions'
};
export default registrationColumn;