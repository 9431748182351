function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import config from 'config/environment';
import { requestHeader, handleResponse, handleResponseWithFormFields } from '../helpers/request-helpers';
var registrationService = {
  register: register,
  createPaymentIntent: createPaymentIntent,
  create: create,
  update: update,
  bulkEdit: bulkEdit,
  sendEmail: sendEmail,
  sendSms: sendSms,
  addVouchers: addVouchers,
  removeVouchers: removeVouchers,
  addVotingPower: addVotingPower,
  createShareLink: createShareLink,
  deleteRegistrations: deleteRegistrations,
  decline: decline,
  saveGuest: saveGuest
};
var handleRegistrationFormFields = function handleRegistrationFormFields(response) {
  return response.text().then(function (text) {
    var data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem('user');
        window.location.reload();
      }
      var error = data || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
};
function register(eventId, form, paymentIntentId, paymentIntentClientSecret, registrationAccessKey, reservations, language) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      eventId: eventId,
      formFields: form,
      paymentIntentId: paymentIntentId,
      paymentIntentClientSecret: paymentIntentClientSecret,
      registrationAccessKey: registrationAccessKey,
      reservations: reservations,
      language: language
    })
  };
  return fetch("".concat(config.apiUrl, "/registration"), requestOptions).then(handleRegistrationFormFields);
}
function createPaymentIntent(eventId, form, price, registrationAccessKey, reservations, language) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      eventId: eventId,
      formFields: form,
      price: price,
      registrationAccessKey: registrationAccessKey,
      reservations: reservations,
      language: language
    })
  };
  return fetch("".concat(config.apiUrl, "/registration/createPaymentIntent"), requestOptions).then(handleRegistrationFormFields);
}
function create(eventId, values, reservations) {
  var requestOptions = {
    method: 'PUT',
    headers: requestHeader(),
    body: JSON.stringify({
      eventId: eventId,
      values: values,
      reservations: reservations
    })
  };
  return fetch("".concat(config.apiUrl, "/registration/create"), requestOptions).then(handleRegistrationFormFields);
}
function update(eventId, values, reservations) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      eventId: eventId,
      registrationId: values.id,
      values: values,
      reservations: reservations
    })
  };
  return fetch("".concat(config.apiUrl, "/registration/update"), requestOptions).then(handleRegistrationFormFields);
}
function bulkEdit(eventId, data, filter) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify(_objectSpread({
      eventId: eventId,
      filter: filter
    }, data))
  };
  return fetch("".concat(config.apiUrl, "/registration/bulkEdit"), requestOptions).then(handleRegistrationFormFields);
}
function sendEmail(eventId, sender, template, subject, filter, sendDateTime, skipInvalid) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      eventId: eventId,
      senderIdentityId: sender,
      filter: filter,
      subject: subject,
      template: template,
      sendDateTime: sendDateTime,
      skipInvalid: skipInvalid
    })
  };
  return fetch("".concat(config.apiUrl, "/registration/sendEmail"), requestOptions).then(handleRegistrationFormFields);
}
function sendSms(eventId, sender, template, filter, sendDateTime, skipInvalid) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      eventId: eventId,
      sender: sender,
      template: template,
      filter: filter,
      sendDateTime: sendDateTime,
      skipInvalid: skipInvalid
    })
  };
  return fetch("".concat(config.apiUrl, "/registration/sendSms"), requestOptions).then(handleRegistrationFormFields);
}
function addVouchers(eventId, totalVouchers, filter) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      eventId: eventId,
      filter: filter,
      totalVouchers: totalVouchers
    })
  };
  return fetch("".concat(config.apiUrl, "/registration/addVouchers"), requestOptions).then(handleRegistrationFormFields);
}
function removeVouchers(eventId, totalVouchers, filter) {
  var requestOptions = {
    method: 'DELETE',
    headers: requestHeader(),
    body: JSON.stringify({
      eventId: eventId,
      filter: filter,
      totalVouchers: totalVouchers
    })
  };
  return fetch("".concat(config.apiUrl, "/registration/deleteVouchers"), requestOptions).then(handleRegistrationFormFields);
}
function addVotingPower(eventId, votes, filter) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      eventId: eventId,
      filter: filter,
      votes: votes
    })
  };
  return fetch("".concat(config.apiUrl, "/registration/addVotingPower"), requestOptions).then(handleRegistrationFormFields);
}
function createShareLink(eventId, name, pinCode, filter) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      name: name,
      pinCode: pinCode,
      filter: filter
    })
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventId, "/links/create"), requestOptions).then(handleRegistrationFormFields);
}
function deleteRegistrations(eventId, filter) {
  var requestOptions = {
    method: 'DELETE',
    headers: requestHeader(),
    body: JSON.stringify({
      filter: filter
    })
  };
  return fetch("".concat(config.apiUrl, "/participants/").concat(eventId), requestOptions).then(handleResponse);
}
function decline(eventId, registrationAccessKey) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      eventId: eventId,
      registrationAccessKey: registrationAccessKey
    })
  };
  return fetch("".concat(config.apiUrl, "/registration/decline"), requestOptions).then(handleResponseWithFormFields);
}
function saveGuest(eventId, parentRegistrationId, registrationId, values, reservations, contextId) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify({
      eventId: eventId,
      parentRegistrationId: parentRegistrationId,
      registrationId: registrationId,
      values: values,
      reservations: reservations,
      contextId: contextId
    })
  };
  return fetch("".concat(config.apiUrl, "/registration/saveGuest"), requestOptions).then(handleRegistrationFormFields);
}
export default registrationService;