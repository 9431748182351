import agendaConstants from '../constants/actions/agenda-constants';
import myAgendaConstants from '../constants/actions/my-agenda-constants';
import agendaService from '../services/my-agenda-service';
var myAgendaActions = {
  getMyAgenda: getMyAgenda,
  getMyAgendaStatistics: getMyAgendaStatistics,
  deleteMyAgendaParticiapnt: deleteMyAgendaParticiapnt,
  addMyAgendaParticiapnt: addMyAgendaParticiapnt
};
function getMyAgenda(eventIdOrSlug, userIds, sessionId, agendaId) {
  return function (dispatch) {
    dispatch(request());
    agendaService.getMyAgenda(eventIdOrSlug, userIds, sessionId, agendaId).then(function (result) {
      return dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: myAgendaConstants.GET_MYAGENDA_REQUEST
    };
  }
  function success(result) {
    return {
      type: myAgendaConstants.GET_MYAGENDA_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: myAgendaConstants.GET_MYAGENDA_ERROR,
      error: error
    };
  }
}
function getMyAgendaStatistics(eventIdOrSlug) {
  return function (dispatch) {
    dispatch(request());
    agendaService.getMyAgendaStatistics(eventIdOrSlug).then(function (result) {
      return dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: myAgendaConstants.GET_MYAGENDA_STATISTICS_REQUEST
    };
  }
  function success(result) {
    return {
      type: myAgendaConstants.GET_MYAGENDA_STATISTICS_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: myAgendaConstants.GET_MYAGENDA_STATISTICS_ERROR,
      error: error
    };
  }
}
function deleteMyAgendaParticiapnt(eventIdOrSlug, agendaId, sessionId, registrationId, callback) {
  return function (dispatch) {
    dispatch(request());
    agendaService.deleteMyAgendaParticiapnt(eventIdOrSlug, agendaId, sessionId, registrationId).then(function (result) {
      dispatch(success(result, dispatch));
      if (callback) {
        callback();
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: myAgendaConstants.DELETE_MYAGENDA_PARTICIPANT_REQUEST
    };
  }
  function success(result, dispatch) {
    dispatch({
      type: agendaConstants.UPDATE_SESSION_SUCCESS,
      result: result
    });
    return {
      type: myAgendaConstants.DELETE_MYAGENDA_PARTICIPANT_SUCCESS,
      registrationId: registrationId
    };
  }
  function failure(error) {
    return {
      type: myAgendaConstants.DELETE_MYAGENDA_PARTICIPANT_ERROR,
      error: error
    };
  }
}
function addMyAgendaParticiapnt(eventIdOrSlug, agendaId, sessionId, registrationId, callback) {
  return function (dispatch) {
    dispatch(request());
    agendaService.addMyAgendaParticiapnt(eventIdOrSlug, agendaId, sessionId, registrationId).then(function (result) {
      dispatch(success(result, dispatch));
      if (callback) {
        callback();
      }
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: myAgendaConstants.ADD_MYAGENDA_PARTICIPANT_REQUEST
    };
  }
  function success(result, dispatch) {
    dispatch({
      type: agendaConstants.UPDATE_SESSION_SUCCESS,
      result: result
    });
    return {
      type: myAgendaConstants.ADD_MYAGENDA_PARTICIPANT_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: myAgendaConstants.ADD_MYAGENDA_PARTICIPANT_ERROR,
      error: error
    };
  }
}
export default myAgendaActions;