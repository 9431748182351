import registrationService from '../services/registration-service';
import eventService from '../services/event-service';
import { publicRegistrationConstants, registrationsConstants } from '../constants/actions';
var registrationActions = {
  getForm: getForm,
  getFormPreview: getFormPreview,
  register: register,
  createPaymentIntent: createPaymentIntent,
  cancelCheckout: cancelCheckout,
  createRegistration: createRegistration,
  updateRegistration: updateRegistration,
  bulkEdit: bulkEdit,
  sendEmail: sendEmail,
  sendSms: sendSms,
  addVouchers: addVouchers,
  removeVouchers: removeVouchers,
  addVotingPower: addVotingPower,
  createShareLink: createShareLink,
  clearMessages: clearMessages,
  deleteRegistrations: deleteRegistrations,
  decline: decline,
  saveGuest: saveGuest
};
function getForm(eventId, payload, callback) {
  return function (dispatch) {
    dispatch(request());
    eventService.getRegistrationPage(eventId, payload).then(function (result) {
      if (callback) {
        callback(result);
      }
      dispatch(success(result));
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: publicRegistrationConstants.GET_FORM
    };
  }
  function success(response) {
    return {
      type: publicRegistrationConstants.GET_FORM_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: publicRegistrationConstants.GET_FORM_FAILURE,
      response: response
    };
  }
}
function getFormPreview(eventId, callback) {
  return function (dispatch) {
    dispatch(request());
    eventService.getRegistrationPagePreview(eventId).then(function (result) {
      if (callback) {
        callback(result);
      }
      dispatch(success(result));
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: publicRegistrationConstants.GET_FORM_PREVIEW
    };
  }
  function success(response) {
    return {
      type: publicRegistrationConstants.GET_FORM_PREVIEW_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: publicRegistrationConstants.GET_FORM_PREVIEW_FAILURE,
      response: response
    };
  }
}
function register(eventId, payload, paymentIntentId, paymentIntentClientSecret, registrationAccessKey, reservations, language, callback) {
  return function (dispatch) {
    dispatch(request());
    registrationService.register(eventId, payload, paymentIntentId, paymentIntentClientSecret, registrationAccessKey, reservations, language).then(function (result) {
      dispatch(success(result));
      if (callback) {
        callback();
      }
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: publicRegistrationConstants.REGISTER
    };
  }
  function success(response) {
    return {
      type: publicRegistrationConstants.REGISTER_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: publicRegistrationConstants.REGISTER_FAILURE,
      response: response
    };
  }
}
function createPaymentIntent(eventId, payload, price, registrationAccessKey, reservations, language) {
  return function (dispatch) {
    dispatch(request());
    registrationService.createPaymentIntent(eventId, payload, price, registrationAccessKey, reservations, language).then(function (result) {
      return dispatch(success(result));
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: publicRegistrationConstants.CREATE_PAYMENT_INTENT
    };
  }
  function success(response) {
    return {
      type: publicRegistrationConstants.CREATE_PAYMENT_INTENT_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: publicRegistrationConstants.CREATE_PAYMENT_INTENT_FAILURE,
      response: response
    };
  }
}
function cancelCheckout() {
  return function (dispatch) {
    dispatch(request());
  };
  function request() {
    return {
      type: publicRegistrationConstants.CANCEL_CHECKOUT
    };
  }
}
function createRegistration(eventId, values, reservations, onRegistrationUpdated) {
  return function (dispatch) {
    dispatch(request());
    registrationService.create(eventId, values, reservations).then(function (result) {
      dispatch(success(result));
      if (onRegistrationUpdated) {
        onRegistrationUpdated(result.registrationId);
      }
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: registrationsConstants.CREATE_REGISTRATION_REQUEST
    };
  }
  function success(response) {
    return {
      type: registrationsConstants.CREATE_REGISTRATION_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: registrationsConstants.CREATE_REGISTRATION_FAILURE,
      response: response
    };
  }
}
function updateRegistration(eventId, values, reservations, onRegistrationUpdated) {
  return function (dispatch) {
    dispatch(request());
    registrationService.update(eventId, values, reservations).then(function (result) {
      dispatch(success(result));
      if (onRegistrationUpdated) {
        onRegistrationUpdated();
      }
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: registrationsConstants.UPDATE_REGISTRATION_REQUEST
    };
  }
  function success(response) {
    return {
      type: registrationsConstants.UPDATE_REGISTRATION_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: registrationsConstants.UPDATE_REGISTRATION_FAILURE,
      response: response
    };
  }
}
function bulkEdit(eventId, data, filter, successCallback) {
  return function (dispatch) {
    dispatch(request());
    registrationService.bulkEdit(eventId, data, filter).then(function (result) {
      dispatch(success(result));
      if (successCallback) {
        successCallback();
      }
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: registrationsConstants.BULK_EDIT_REQUEST
    };
  }
  function success(response) {
    return {
      type: registrationsConstants.BULK_EDIT_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: registrationsConstants.BULK_EDIT_FAILURE,
      response: response
    };
  }
}
function sendEmail(eventId, sender, template, subject, filter, sendDateTime, skipInvalid) {
  return function (dispatch) {
    dispatch(request());
    registrationService.sendEmail(eventId, sender, template, subject, filter, sendDateTime, skipInvalid).then(function (result) {
      dispatch(success(result));
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: registrationsConstants.SEND_EMAIL_REQUEST
    };
  }
  function success(response) {
    return {
      type: registrationsConstants.SEND_EMAIL_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: registrationsConstants.SEND_EMAIL_FAILURE,
      response: response
    };
  }
}
function sendSms(eventId, sender, template, filter, sendDateTime, skipInvalid) {
  return function (dispatch) {
    dispatch(request());
    registrationService.sendSms(eventId, sender, template, filter, sendDateTime, skipInvalid).then(function (result) {
      dispatch(success(result));
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: registrationsConstants.SEND_SMS_REQUEST,
      sendDateTime: sendDateTime
    };
  }
  function success(response) {
    return {
      type: registrationsConstants.SEND_SMS_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: registrationsConstants.SEND_SMS_FAILURE,
      response: response
    };
  }
}
function addVouchers(eventId, totalVouchers, filter) {
  return function (dispatch) {
    dispatch(request());
    registrationService.addVouchers(eventId, totalVouchers, filter).then(function (result) {
      dispatch(success(result));
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: registrationsConstants.ADD_VOUCHERS_REQUEST
    };
  }
  function success(response) {
    return {
      type: registrationsConstants.ADD_VOUCHERS_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: registrationsConstants.ADD_VOUCHERS_FAILURE,
      response: response
    };
  }
}
function removeVouchers(eventId, totalVouchers, filter) {
  return function (dispatch) {
    dispatch(request());
    registrationService.removeVouchers(eventId, totalVouchers, filter).then(function (result) {
      dispatch(success(result));
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: registrationsConstants.REMOVE_VOUCHERS_REQUEST
    };
  }
  function success(response) {
    return {
      type: registrationsConstants.REMOVE_VOUCHERS_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: registrationsConstants.REMOVE_VOUCHERS_FAILURE,
      response: response
    };
  }
}
function addVotingPower(eventId, votes, filter) {
  return function (dispatch) {
    dispatch(request());
    registrationService.addVotingPower(eventId, votes, filter).then(function (result) {
      dispatch(success(result));
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: registrationsConstants.ADD_VOTING_POWER_REQUEST
    };
  }
  function success(response) {
    return {
      type: registrationsConstants.ADD_VOTING_POWER_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: registrationsConstants.ADD_VOTING_POWER_SUCCESS,
      response: response
    };
  }
}
function createShareLink(eventId, name, pinCode, filter) {
  return function (dispatch) {
    dispatch(request());
    registrationService.createShareLink(eventId, name, pinCode, filter).then(function (result) {
      dispatch(success(result));
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: registrationsConstants.CREATE_SHARE_LINK_REQUEST
    };
  }
  function success(response) {
    return {
      type: registrationsConstants.CREATE_SHARE_LINK_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: registrationsConstants.CREATE_SHARE_LINK_FAILURE,
      response: response
    };
  }
}
function deleteRegistrations(eventId, filter, callback) {
  return function (dispatch) {
    dispatch(request());
    registrationService.deleteRegistrations(eventId, filter).then(function (result) {
      dispatch(success(result));
      if (callback) {
        callback();
      }
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: registrationsConstants.DELETE_REQUEST
    };
  }
  function success(response) {
    return {
      type: registrationsConstants.DELETE_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: registrationsConstants.DELETE_FAILURE,
      response: response
    };
  }
}
function decline(eventId, registrationAccessKey) {
  return function (dispatch) {
    dispatch(request());
    registrationService.decline(eventId, registrationAccessKey).then(function (result) {
      dispatch(success(result));
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: publicRegistrationConstants.DECLINE_REQUEST
    };
  }
  function success(response) {
    return {
      type: publicRegistrationConstants.DECLINE_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: publicRegistrationConstants.DECLINE_FAILURE,
      response: response
    };
  }
}
function saveGuest(eventId, parentRegistrationId, registrationId, values, reservations, contextId, callback) {
  return function (dispatch) {
    dispatch(request());
    registrationService.saveGuest(eventId, parentRegistrationId, registrationId, values, reservations, contextId).then(function (result) {
      dispatch(success(result));
      if (callback) {
        callback();
      }
    }, function (response) {
      return dispatch(failure(response));
    });
  };
  function request() {
    return {
      type: registrationsConstants.SAVE_GUEST_REQUEST
    };
  }
  function success(response) {
    return {
      type: registrationsConstants.SAVE_GUEST_SUCCESS,
      response: response
    };
  }
  function failure(response) {
    return {
      type: registrationsConstants.SAVE_GUEST_FAILURE,
      response: response
    };
  }
}
function clearMessages() {
  return function (dispatch) {
    return dispatch({
      type: registrationsConstants.CLEAR_MESSAGES
    });
  };
}
export default registrationActions;