import "core-js/modules/es.array.concat.js";
import { VIDEOURL } from '../../../constants/video-url-constants';
function toggleState(editor, toggler) {
  editor.on('NodeChange', toggler);
  return function () {
    return editor.off('NodeChange', toggler);
  };
}
function extractFromIframe(editor, iframe) {
  var _iframe$innerText;
  var dom = editor.dom;
  var label = (_iframe$innerText = iframe === null || iframe === void 0 ? void 0 : iframe.innerText) !== null && _iframe$innerText !== void 0 ? _iframe$innerText : '';
  var url = iframe ? dom.getAttrib(iframe, 'href') : '';
  var videoName = iframe ? dom.getAttrib(iframe, 'data-tappin-video-name') : null;
  var videoType = iframe ? dom.getAttrib(iframe, 'data-tappin-video-type') : null;
  return {
    label: label,
    url: url,
    videoName: videoName,
    videoType: videoType
  };
}
function collect(editor, linkNode) {
  var iframe = extractFromIframe(editor, linkNode);
  return {
    iframe: iframe
  };
}
export function buildIframe(data, node) {
  var _node$tagName, _iframe$style;
  var isIframe = (node === null || node === void 0 || (_node$tagName = node.tagName) === null || _node$tagName === void 0 ? void 0 : _node$tagName.toLowerCase()) === 'iframe';
  var iframe = isIframe ? node : document.createElement('iframe');
  iframe.setAttribute('allowFullScreen', 'allowFullScreen');
  iframe.setAttribute('data-mce-style', iframe === null || iframe === void 0 || (_iframe$style = iframe.style) === null || _iframe$style === void 0 ? void 0 : _iframe$style.cssText);
  iframe.src = "".concat(VIDEOURL).concat(data.video.value, "&source=flowplayer");
  return iframe;
}
export { toggleState, collect };