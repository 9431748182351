var myAgendaConstants = {
  GET_MYAGENDA_REQUEST: 'GET_MYAGENDA_REQUEST',
  GET_MYAGENDA_SUCCESS: 'GET_MYGENDA_SUCCESS',
  GET_MYAGENDA_ERROR: 'GET_MYAGENDA_ERROR',
  GET_MYAGENDA_STATISTICS_REQUEST: 'GET_MYAGENDA_STATISTICS_REQUEST',
  GET_MYAGENDA_STATISTICS_SUCCESS: 'GET_MYAGENDA_STATISTICS_SUCCESS',
  GET_MYAGENDA_STATISTICS_ERROR: 'GET_MYAGENDA_STATISTICS_ERROR',
  DELETE_MYAGENDA_PARTICIPANT_REQUEST: 'DELETE_MYAGENDA_PARTICIPANT_REQUEST',
  DELETE_MYAGENDA_PARTICIPANT_SUCCESS: 'DELETE_MYAGENDA_PARTICIPANT_SUCCESS',
  DELETE_MYAGENDA_PARTICIPANT_ERROR: 'DELETE_MYAGENDA_PARTICIPANT_ERROR',
  ADD_MYAGENDA_PARTICIPANT_REQUEST: 'ADD_MYAGENDA_PARTICIPANT_REQUEST',
  ADD_MYAGENDA_PARTICIPANT_SUCCESS: 'ADD_MYAGENDA_PARTICIPANT_SUCCESS',
  ADD_MYAGENDA_PARTICIPANT_ERROR: 'ADD_MYAGENDA_PARTICIPANT_ERROR'
};
export default myAgendaConstants;