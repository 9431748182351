import myAgendaConstants from '../constants/actions/my-agenda-constants';
import agendaService from '../services/my-agenda-service';
var myAgendaActions = {
  getMyAgenda: getMyAgenda,
  getMyAgendaStatistics: getMyAgendaStatistics
};
function getMyAgenda(eventIdOrSlug, userIds, sessionId, agendaId) {
  return function (dispatch) {
    dispatch(request());
    agendaService.getMyAgenda(eventIdOrSlug, userIds, sessionId, agendaId).then(function (result) {
      return dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: myAgendaConstants.GET_MYAGENDA_REQUEST
    };
  }
  function success(result) {
    return {
      type: myAgendaConstants.GET_MYAGENDA_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: myAgendaConstants.GET_MYAGENDA_ERROR,
      error: error
    };
  }
}
function getMyAgendaStatistics(eventIdOrSlug) {
  return function (dispatch) {
    dispatch(request());
    agendaService.getMyAgendaStatistics(eventIdOrSlug).then(function (result) {
      return dispatch(success(result));
    }, function (error) {
      return dispatch(failure(error));
    });
  };
  function request() {
    return {
      type: myAgendaConstants.GET_MYAGENDA_STATISTICS_REQUEST
    };
  }
  function success(result) {
    return {
      type: myAgendaConstants.GET_MYAGENDA_STATISTICS_SUCCESS,
      result: result
    };
  }
  function failure(error) {
    return {
      type: myAgendaConstants.GET_MYAGENDA_STATISTICS_ERROR,
      error: error
    };
  }
}
export default myAgendaActions;