var platformModules = {
  SAVE_URL: 'saveUrl',
  REGISTER: 'register',
  NEWS_FEED: 'newsFeed',
  POSTING: 'posting',
  COMMENTING: 'commenting',
  INFO_PAGES: 'infoPages',
  DOCUMENTS: 'documents',
  PARTICIPANTS: 'participants',
  PARTICIPANT_EMAIL: 'participantEmail',
  PARTICIPANT_PHONE: 'participantPhone',
  USER_SETTINGS: 'userSettings',
  SUPPORT: 'support',
  GAMIFICATION: 'gamification',
  GAMIFICATION_URL: 'gamification_url',
  ONE_ON_ONE_CHAT: '1on1_chat',
  LOGIN: 'login',
  LOGIN_CAPTION: 'login_caption',
  LOGIN_SCHEDULE: 'login_schedule',
  SPEAKERS: 'speakers',
  PLATFORM_PAGES: 'platform_pages',
  AGENDA: 'agenda',
  SPONSORS: 'sponsors',
  QR_TICKETS: 'qr_tickets',
  VOUCHERS: 'vouchers',
  MULTIPLE_SCANNED_IN: 'multiple_scanned_in',
  SPEAKERS_FILTER: 'speaker_filter',
  SPONSOR_FILTER: 'sponsor_filter',
  BOOKINGS: 'bookings',
  VOTING_POWER: 'voting_power'
};
export default platformModules;