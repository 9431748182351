import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.array.concat.js";
import config from 'config/environment';
import { handleResponse, requestHeader } from '../helpers/request-helpers';
var actions = {
  getMyAgenda: getMyAgenda,
  getMyAgendaStatistics: getMyAgendaStatistics
};
function getMyAgenda(eventIdOrSlug, userIds, sessionId, agendaId) {
  var requestOptions = {
    method: 'POST',
    headers: requestHeader(),
    body: JSON.stringify(userIds)
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventIdOrSlug, "/agenda/").concat(agendaId, "/session/").concat(sessionId, "/myAgenda"), requestOptions).then(handleResponse);
}
function getMyAgendaStatistics(eventIdOrSlug) {
  var requestOptions = {
    method: 'GET',
    headers: requestHeader()
  };
  return fetch("".concat(config.apiUrl, "/event/").concat(eventIdOrSlug, "/agenda/session/myAgenda/statistics"), requestOptions).then(handleResponse);
}
export default actions;